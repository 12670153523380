/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.article {
  color: #fff;
  line-height: 1.7;
}
@media (max-width: 767px) {
  .article {
    padding: 20px;
  }
}
.article > .maxArticleWrapper {
  margin: 0 auto;
  position: relative;
  max-width: 1400px;
}
@media (min-width: 768px) {
  .article .verticalAlign {
    align-items: center;
    display: flex;
  }
}
.article .left {
  margin-left: -15px;
}
.article .left > .text {
  text-align: left;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .article .left > .text {
    font-size: 17px;
  }
}
.article .right {
  padding-left: 15px;
  margin-right: -15px;
}
.article .right > .text {
  text-align: left;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .article .right > .text {
    font-size: 17px;
  }
}
.article .image {
  padding: 20px;
}
.article .image .video {
  height: 350px;
  width: 100%;
}
@media (max-width: 767px) {
  .article .image .video {
    height: 200px;
  }
}
.article .icon {
  margin-right: 40px;
}
.article ul {
  list-style-image: url('/graphics/generic/hyperlink-arrow.svg');
  padding-left: 30px;
  margin: 0;
}
.article .title {
  font-family: 'Cabin', 'Verdana';
  font-size: 35px;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .article .title {
    font-size: 30px;
  }
}
.article .subtitle {
  font-size: 24px;
  color: #df96f6;
  margin: -20px 0 40px 30px;
}
@media (max-width: 767px) {
  .article .subtitle {
    font-size: 21px;
    margin: -20px 0 40px;
  }
}
.article .section > .subtitle,
.article .section > div > .subtitle {
  color: #ffcbff;
}
