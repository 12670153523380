/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.articleContainer {
  background: #402647;
}
.articleContainer .article {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 17px;
}
@media (max-width: 767px) {
  .articleContainer .article {
    padding: 0;
  }
}
.articleContainer .article:nth-child(even) {
  background: #402647;
}
.articleContainer .article:nth-child(odd) {
  background: #4b2d54;
}
.fullWidthSection .articleContainer,
.section .articleContainer {
  margin-top: 40px;
  box-shadow: rgba(204, 204, 204, 0.4) 0 0 20px;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .fullWidthSection .articleContainer,
  .section .articleContainer {
    box-shadow: none;
    border-radius: 0;
  }
}
.fullWidthSection .articleContainer .article,
.section .articleContainer .article {
  font-size: 17px;
  color: #2d2d2d;
}
.fullWidthSection .articleContainer .article:nth-child(even),
.section .articleContainer .article:nth-child(even) {
  background: #fff;
}
.fullWidthSection .articleContainer .article:nth-child(odd),
.section .articleContainer .article:nth-child(odd) {
  background: #fff;
}
.fullWidthSection .articleContainer .article a,
.section .articleContainer .article a {
  color: #0084bb !important;
  font-weight: bold;
}
.fullWidthSection .articleContainer .article a:hover,
.section .articleContainer .article a:hover {
  color: #006088 !important;
}
.fullWidthSection .articleContainer .article ul a,
.section .articleContainer .article ul a {
  margin-top: -3px;
}
.fullWidthSection .articleContainer .article .title,
.section .articleContainer .article .title {
  color: #9158a3;
}
